import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import '../Styles/navbar.css'
import images from '../images';
import TutorialIcon from './TutorialIcon';
import Switch from 'react-switch';

const SNotesNavbarDesktop = ({
    showTutorial,
    darkMode,
    toggleDarkMode
}) => {
    return (
        <Navbar id="snotes-navbar-mobile" className="snotes-navbar" style={{background: darkMode ? '#303030' : '#ffffff'}} expand="lg">
            <Navbar.Brand href="#home"><img src={images.logo} alt="SpeedNotes.app" id="snotes-navbar-logo" /></Navbar.Brand>
            <div>
                <TutorialIcon showTutorial={showTutorial} darkMode={darkMode} />
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className={darkMode ? "darkmode" : ""}>
                    <Nav className="mr-auto">
                        <Nav.Link className={`snotes-navbar-link${darkMode ? " darkmode-font" : ""}`} href="#about">About</Nav.Link>
                        <Nav.Link className={`snotes-navbar-link${darkMode ? " darkmode-font" : ""}`} href="blog">Blog</Nav.Link>
                        <div id="mobile-darkmode-container">
                            <div id="darkmode-link"><div className={`snotes-navbar-link${darkMode ? " darkmode-font" : ""}`} onClick={toggleDarkMode}>Dark Mode</div></div>
                            <div className="flex-col-center">
                                <Switch 
                                    onChange={toggleDarkMode}
                                    checked={darkMode}
                                    checkedIcon={false}    
                                    uncheckedIcon={false}    
                                    onColor={"#124c6d"}
                                    onHandleColor={"#2D9CDB"}
                                />
                            </div>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>
    )
}

export default SNotesNavbarDesktop;