import React from 'react';

const FontSizeArrow = ({
    style,
    darkMode
}) => {
    return (
        <div style={style} className="tutorial-arrow">
            <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M101.163 85.776C102.453 75.313 100.51 63.2646 96.5358 53.5423C94.2567 48.0659 90.9454 43.0274 87.3625 38.2713C85.5 35.8259 83.5018 33.5216 81.4358 31.288C80.7949 30.6115 77.7581 28.4689 78.0154 27.5648C78.9199 24.5731 88.1076 34.385 89.0372 35.5387C92.8979 40.2884 96.626 45.3175 99.186 50.9258C103.5 60.295 106.467 71.5601 105.939 81.9369C105.66 87.9548 105.451 94.0402 103.849 99.9497C103.142 102.453 102.294 104.891 101.096 107.198C100.299 108.805 99.4342 110.483 98.4271 112.026C97.6884 113.148 96.1148 114.221 95.6554 115.406C97.9504 115.492 100.406 113.502 102.766 113.379C105.612 113.246 104.409 115.346 102.832 116.28C100.297 117.857 97.4752 119.027 94.7241 120.264C92.3864 121.353 89.7205 123.279 87.787 120.766C86.1392 118.592 85.309 115.708 84.141 113.247C83.3392 111.607 80.8344 108.416 81.903 106.526C85.1736 100.648 88.6978 115.01 89.4113 115.823C90.1579 115.046 90.5477 113.863 91.1523 112.951C92.0252 111.618 93.1111 110.488 93.984 109.155C95.6651 106.699 97.062 103.973 98.1811 101.252C100.159 96.5781 101.28 90.8867 101.163 85.776C101.18 86.5357 101.632 81.965 101.163 85.776Z" fill={darkMode ? "#E0E0E0" : "#465967"}/>
            </svg>
        </div>
    )
}

export default FontSizeArrow;