import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import '../Styles/navbar.css'
import images from '../images';
import TutorialIcon from './TutorialIcon';
import Switch from 'react-switch';
import SocialLink from './SocialLink';

const SNotesNavbarDesktop = ({
    showTutorial,
    darkMode,
    toggleDarkMode
}) => {
    return (
        <Navbar className="snotes-navbar" id="snotes-navbar-desktop" style={{background: darkMode ? '#303030' : '#ffffff'}}>
            <div id="snotes-navbar-main">
                <div className="flex-col-center navbar-desktop-section">
                    <Nav className="mr-auto">
                        <Nav.Link className={`snotes-navbar-link${darkMode ? " darkmode-font" : ""}`} href="#about">About</Nav.Link>
                        <Nav.Link className={`snotes-navbar-link${darkMode ? " darkmode-font" : ""}`} href="blog">Blog</Nav.Link>
                        <div id="nav-social-container">
                            <SocialLink name="facebook" />
                            <SocialLink name="instagram" />
                            <SocialLink name="twitter" />
                        </div>
                    </Nav>
                </div>
                <Navbar.Brand href="#home"><img src={images.logo} alt="SpeedNotes.app" id="snotes-navbar-logo" /></Navbar.Brand>
                <div className="flex-space-evenly navbar-desktop-section">
                    <div className="darkmode-switch-container">
                        <div id="darkmode-link"><div className={`snotes-navbar-link${darkMode ? " darkmode-font" : ""}`} onClick={toggleDarkMode}>Dark Mode</div></div>
                        <div className="flex-col-center">
                            <Switch 
                                onChange={toggleDarkMode}
                                checked={darkMode}
                                checkedIcon={false}    
                                uncheckedIcon={false}    
                                onColor={"#124c6d"}
                                onHandleColor={"#2D9CDB"}
                            />
                        </div>
                    </div>
                    <TutorialIcon showTutorial={showTutorial} darkMode={darkMode} />
                </div>
            </div>
        </Navbar>
    )
}

export default SNotesNavbarDesktop;