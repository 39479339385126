import React, { Component, Fragment } from 'react';
import images from '../images';
import SNotesNavbarDesktop from './SNotesNavbarDesktop';
import SNotesNavbarMobile from './SNotesNavbarMobile';
import TextDisplay from './TextDisplay';
import Tutorial from './Tutorial';
import Footer from './Footer';
import RestartBtn from './ControlButtons/RestartBtn';
import SkipBackBtn from './ControlButtons/SkipBackBtn';
import SkipFwdBtn from './ControlButtons/SkipFwdBtn';
import PlayPauseBtn from './ControlButtons/PlayPauseBtn';
// import TtsBtn from './ControlButtons/TtsBtn';
import '../Styles/whyUse.css';
import TextArea from './TextArea';
import * as Sentry from '@sentry/react';

class Main extends Component {
    constructor(props) {
        super(props);

        this.state = {
            darkMode: false,
            placeholder: "Read faster with SpeedNotes! Enter your text here.",
            rawText: "",
            wpm: 200,
            wordCount: 0,
            sentenceCount: 0,
            currentWord: "Read",
            currentWordLocation: -1,
            selectedFontSize: 72,
            fontSizes: [
                12,
                16,
                24,
                40,
                48,
                60,
                72,
                84,
                96,
                108,
                132,
                160,
                190
            ]
        };

        this.setRawText = this.setRawText.bind(this);
        this.parseString = this.parseString.bind(this);
        this.loopSentences = this.loopSentences.bind(this);
        this.pause = this.pause.bind(this);
        this.play = this.play.bind(this);
        this.skipBack = this.skipBack.bind(this);
        this.skipFwd = this.skipFwd.bind(this);
        this.restart = this.restart.bind(this);
        this.setSpeed = this.setSpeed.bind(this);
        this.showTutorial = this.showTutorial.bind(this);
        this.closeTutorial = this.closeTutorial.bind(this);
        this.handleWPMChange = this.handleWPMChange.bind(this);
        this.changeFontSize = this.changeFontSize.bind(this);
        this.detectDarkMode = this.detectDarkMode.bind(this);
        this.toggleDarkMode = this.toggleDarkMode.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.toggleShowPause = this.toggleShowPause.bind(this);
        this.highlightWord = this.highlightWord.bind(this);
        this.clearLoop = this.clearLoop.bind(this);
        this.setCurrentWordLocation = this.setCurrentWordLocation.bind(this);
        this.removeHighlight = this.removeHighlight.bind(this);
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = 0;
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        const { isMobile, selectedFontSize } = this.state;
        const { innerWidth } = window;
        if (innerWidth > 767 && isMobile) {
            this.setState({ isMobile: false })
        } else if (innerWidth < 768 && !isMobile) {
            if (selectedFontSize > 48)
                this.setState({ selectedFontSize: 48 })

            this.setState({ isMobile: true })
        }
    }

    componentDidMount() {
        this.detectDarkMode();

        this.handleResize();
    }

    detectDarkMode() {
        try {
            if (localStorage.getItem('speednotes_darkmode') ||
                (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
                this.toggleDarkMode()
            }
        } catch (error) {
            console.error(error);
        }
    }

    setCurrentWordLocation(sentenceCount) {
        const { wordArr } = this.state;
        let currentWordLocation = 0;
        for (let i = 0; i < sentenceCount; i++) {
            currentWordLocation += wordArr[i].length;
        }

        return currentWordLocation - 1;
    }

    toggleDarkMode() {
        const { darkMode, paused } = this.state;
        if (!darkMode) {
            localStorage.setItem('speednotes_darkmode', true)
        } else {
            localStorage.removeItem('speednotes_darkmode')
        }
        const darkModeToggled = darkMode && !paused && this.timer ? true : false;
        this.setState({ darkMode: !darkMode, darkModeToggled })

    }

    showTutorial() {
        window.scrollTo(0, 0)
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('no-scroll')
        const topSection = document.getElementById('snotes-top-section');
        topSection.classList.remove('set-z')
        this.setState({ showTutorial: true })
    }

    closeTutorial() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('no-scroll');
        const topSection = document.getElementById('snotes-top-section');
        topSection.classList.add('set-z')
        this.setState({ showTutorial: false })
    }

    async handleWPMChange(wpm) {
        const { paused } = this.state;
        if (this.timer && !paused) {
            await this.pause();
            this.play(wpm);
        }
        this.setState({ wpm });
    }

    setRawText(rawText) {
        if (this.timer)
            this.clearLoop();

        this.setState({ rawText, paused: false })
    }

    parseString() {
        const { rawText, placeholder } = this.state;
        const text = rawText ? rawText : placeholder;
        const sentenceArr = text.split(".")
        if (!sentenceArr[sentenceArr.length - 1])
            sentenceArr.pop();

        const withPeriods = sentenceArr.map(sentence => {
            return sentence += "."
        })

        let withQuestionMarks = withPeriods.map(sentence => {
            if (sentence.split("?").length > 1) {
                const splitSentence = sentence.split("?");
                for (let i = 0; i < splitSentence.length - 1; i++) {
                    splitSentence[i] += "?"
                }
                return splitSentence.map(s => { return s })
            } else {
                return sentence;
            }
        })

        let withExclamation = withQuestionMarks.flat().map(sentence => {
            if (sentence.split("!").length > 1) {
                const splitSentence = sentence.split("!");
                for (let i = 0; i < splitSentence.length - 1; i++) {
                    splitSentence[i] += "!"
                }
                return [...splitSentence]
            } else {
                return sentence;
            }
        })

        let withCommas = withExclamation.flat().map(sentence => {
            if (sentence.split(",").length > 1) {
                const splitSentence = sentence.split(",");
                for (let i = 0; i < splitSentence.length - 1; i++) {
                    splitSentence[i] += ","
                }
                return [...splitSentence]
            } else {
                return sentence;
            }
        })

        let withSemiColons = withCommas.flat().map(sentence => {
            if (sentence.split(":").length > 1) {
                const splitSentence = sentence.split(":");
                for (let i = 0; i < splitSentence.length - 1; i++) {
                    splitSentence[i] += ":"
                }
                return [...splitSentence]
            } else {
                return sentence;
            }
        })

        let withColons = withSemiColons.flat().map(sentence => {
            if (sentence.split(";").length > 1) {
                const splitSentence = sentence.split(";");
                for (let i = 0; i < splitSentence.length - 1; i++) {
                    splitSentence[i] += ";"
                }
                return [...splitSentence]
            } else {
                return sentence;
            }
        })

        let withoutSpaces = withColons.flat().map(sentence => {
            return sentence.split(" ")
        })

        const wordArr = withoutSpaces.map(sentence => {
            return sentence.filter(word => word !== "");
        })

        const flatWordArr = wordArr.flat();
        let i = 0;
        const wordMap = flatWordArr.map(word => {
            const id = `word-${i}`;
            i++;
            return <span id={id}>{word} </span>
        })

        this.setState({ wordArr, wordMap })
        this.play()
    }

    loopSentences() {
        let { wordArr, wordCount, sentenceCount, currentWord, currentWordLocation } = this.state;
        if (wordArr && wordArr.length) {
            if (wordArr[sentenceCount][wordCount]) {
                currentWord = wordArr[sentenceCount][wordCount];
                wordCount++
                currentWordLocation++;
                this.highlightWord(currentWordLocation)
                this.setState({ wordCount, currentWord, currentWordLocation })
            } else if (wordArr[sentenceCount + 1]) {
                sentenceCount++;
                wordCount = 0;
                this.setState({ wordCount, sentenceCount });
            } else {
                this.clearLoop()
            }
        }
    }

    highlightWord(index) {
        const { darkMode, darkModeToggled } = this.state;
        try {
            const element = document.getElementById(`word-${index}`);
            if (element) {
                element.classList.add('highlight');
                if (darkMode) {
                    element.classList.add('dark-mode');
                }
            }
            if (index ) {
                const lastElement = document.getElementById(`word-${index - 1}`);
                lastElement.classList.remove('highlight');
                if ((darkModeToggled && !darkMode) || (!darkModeToggled && darkMode)) {
                    lastElement.classList.remove('dark-mode');
                }
            }
            this.setState({ darkModeToggled: false })
        } catch (err) {
            console.error(err);
        }
    }

    removeHighlight(currentWordLocation) {
        try {
            const { darkModeToggled, darkMode } = this.state;
            const element = document.getElementById(`word-${currentWordLocation}`);
            element.classList.remove('highlight');
            if ((darkModeToggled && !darkMode) || (!darkModeToggled && darkMode)) {
                element.classList.remove('dark-mode');
            }
        } catch (err) {
            Sentry.captureException(err)
            console.error(err);
        }
    }

    clearLoop(autoFocus) {
        clearInterval(this.timer);
        this.timer = 0;
        this.setState({ wordCount: 0, sentenceCount: 0, currentWordLocation: -1, currentWord: "", showUnderline: false, autoFocus })
    }

    pause() {
        clearInterval(this.timer);
        this.timer = 0;
        this.setState({ paused: true })
    }

    renderCurrentWord() {
        const { currentWord, selectedFontSize, darkMode } = this.state;
        return <TextDisplay word={currentWord} fontSize={selectedFontSize} darkMode={darkMode} />
    }

    skipBack() {
        if (this.timer || this.state.paused) {
            let { sentenceCount, wordCount, currentWord, wordArr, currentWordLocation } = this.state;
            wordCount = 0;
            let settled = false;
            this.removeHighlight(currentWordLocation);
            if (sentenceCount) {
                if (this.isLastCharComma(sentenceCount)) {
                    sentenceCount--
                }
                while (!settled) {
                    sentenceCount--;
                    if (!sentenceCount || !wordArr[sentenceCount].length) {
                        sentenceCount = 0;
                        settled = true;
                    } else {
                        const isComma = this.isLastCharComma(sentenceCount)
                        if (!isComma) {
                            settled = true;
                        }
                    }
                }
            }
            currentWord = wordArr[sentenceCount][wordCount];
            currentWordLocation = this.setCurrentWordLocation(sentenceCount)
            this.highlightWord(currentWordLocation + 1)
            this.setState({ sentenceCount, wordCount, currentWord, currentWordLocation })
        }
    }

    skipFwd() {
        if (this.timer || this.state.paused) {
            let { sentenceCount, wordCount, wordArr, currentWord, currentWordLocation } = this.state;
            this.removeHighlight(currentWordLocation);
            let settled = false;
            wordCount = 0;
            while (!settled) {
                sentenceCount++;
                if (!wordArr[sentenceCount] || !wordArr[sentenceCount].length) {
                    sentenceCount = 0;
                    clearInterval(this.timer);
                    this.timer = 0;
                    currentWord = "";
                    currentWordLocation = -1;
                    settled = true;
                } else {
                    const isComma = this.isLastCharComma(sentenceCount)
                    if (!isComma) {
                        currentWord = wordArr[sentenceCount][wordCount];
                        settled = true;
                    }
                }
            }
            if (currentWord) {
                currentWordLocation = this.setCurrentWordLocation(sentenceCount)
                this.highlightWord(currentWordLocation + 1)
            }

            this.setState({ wordCount, sentenceCount, currentWord, currentWordLocation });
        }
    }

    isLastCharComma(index) {
        const lastWord = this.getLastWord(index - 1)
        const lastChar = this.getLastChar(lastWord);
        let isCommaOrColon;
        switch (lastChar) {
            case ",":
            case ":":
            case ";":
            case "-":
                isCommaOrColon = true;
                break;
            default:
                isCommaOrColon = false;
                break;
        }
        return isCommaOrColon

    }

    getLastWord(index) {
        const { wordArr } = this.state;
        return wordArr[index][wordArr[index].length - 1];
    }

    getLastChar(word) {
        return word[word.length - 1];
    }

    restart() {
        if (this.timer || this.state.paused) {
            let { sentenceCount, wordCount, wordArr, currentWord, currentWordLocation } = this.state;
            this.removeHighlight(currentWordLocation);
            sentenceCount = wordCount = 0;
            currentWord = wordArr[sentenceCount][wordCount]
            currentWordLocation = -1;
            this.highlightWord(0)
            this.setState({ sentenceCount, wordCount, currentWord, currentWordLocation });
        }
    }

    play(wpm) {
        const speed = wpm && !isNaN(wpm) ? wpm : this.state.wpm
        this.timer = setInterval(this.loopSentences, speed)
        this.setState({ paused: false, showUnderline: true })
    }

    setSpeed(value) {
        this.setState({ wpm: value })
    }

    changeFontSize(upsize) {
        const { selectedFontSize, fontSizes } = this.state;
        const index = fontSizes.indexOf(selectedFontSize);
        if (upsize) {
            if (index < fontSizes.length - 1) {
                this.setState({ selectedFontSize: fontSizes[index + 1] })
            }
        } else if (index) {
            this.setState({ selectedFontSize: fontSizes[index - 1] })
        }
    }

    toggleShowPause() {
        const { showPause } = this.state;
        this.setState({ showPause: !showPause });
    }

    renderButtons() {
        const { paused, wpm, darkMode, isMobile } = this.state;
        const { timer } = this;
        const showPause = (timer && !paused) || this.state.showPause ? true : false;
        const firstFour = (
            <Fragment>
                <RestartBtn onclick={this.restart} darkMode={darkMode} />
                <SkipBackBtn onclick={this.skipBack} darkMode={darkMode} />
                <PlayPauseBtn showPause={showPause} onclick={timer ? this.pause : paused ? this.play : this.parseString} />
                <SkipFwdBtn onclick={this.skipFwd} darkMode={darkMode} />
            </Fragment>
        )
        const lastFour = (
            <Fragment>
                <div id="font-buttons">
                    <div className={`font-size-btn pointer${darkMode ? " darkmode" : ""}`} id="font-size-smaller" onClick={() => this.changeFontSize(false)}>A-</div>
                    <div className={`font-size-btn pointer${darkMode ? " darkmode" : ""}`} id="font-size-larger" onClick={() => this.changeFontSize(true)}>A+</div>
                </div>
                <select id="wpm-select" className={`pointer${darkMode ? " darkmode" : ""}`} onChange={e => this.handleWPMChange(e.target.value)} defaultValue={wpm}>
                    <option value={600}>100 wpm</option>
                    <option value={300}>200 wpm</option>
                    <option value={200}>300 wpm</option>
                    <option value={150}>400 wpm</option>
                    <option value={125}>500 wpm</option>
                    <option value={100}>600 wpm</option>
                    <option value={86}>700 wpm</option>
                    <option value={75}>800 wpm</option>
                    <option value={67}>900 wpm</option>
                    <option value={60}>1000 wpm</option>
                </select>
                {/* <TtsBtn darkMode={darkMode} /> */}
            </Fragment>
        )
        if (isMobile) {
            return (
                <Fragment>
                    <div className="btn-row">
                        {firstFour}
                    </div>
                    <div className="btn-row">
                        {lastFour}
                    </div>
                </Fragment>
            )
        } else {
            return (
                <div className="btn-row">
                    {firstFour}
                    {lastFour}
                </div>
            )

        }
    }

    renderHowItWorks() {
        const { darkMode } = this.state;
        return (
            <div id="about">
                <h3 id="hiw-header" className={darkMode ? "darkmode-font" : ""}>How SpeedNotes Works</h3>
                <p id="hiw-copy" className={darkMode ? "darkmode-font" : ""}>
                    SpeedNotes is a free web app that helps students and learners alike read faster. <b>By removing the need to move your eyes while reading, you can read your notes (and learn) much faster.</b>
                </p>
                {this.renderHiwSteps()}
            </div>
        )
    }

    renderHiwSteps() {
        const { darkMode } = this.state;
        return (
            <div className="hiw-steps-container">
                <div className="hiw-step">
                    <div className="hiw-step-number"><img src={images.hiw_1} alt="1" /></div>
                    <div className="hiw-step-label-container">
                        <div className={`hiw-step-label${darkMode ? " darkmode-font" : ""}`}>Paste your notes</div>
                    </div>
                </div>
                <div className="hiw-step">
                    <div className="hiw-step-number"><img src={images.hiw_2} alt="2" /></div>
                    <div className="hiw-step-label-container">
                        <div className={`hiw-step-label${darkMode ? " darkmode-font" : ""}`}>Hit play</div>
                    </div>
                </div>
                <div className="hiw-step">
                    <div className="hiw-step-number"><img src={images.hiw_3} alt="3" /></div>
                    <div className="hiw-step-label-container">
                        <div className={`hiw-step-label${darkMode ? " darkmode-font" : ""}`}>Read faster</div>
                    </div>
                </div>
            </div>
        )
    }

    renderWhyUse() {
        const { darkMode } = this.state;
        return (
            <div className="why-use-container">
                <div className="why-use-section">
                    <h3 id="why-use-header" className={darkMode ? "darkmode-font" : ""}>Why use Speednotes</h3>
                    {this.renderWhyUseBullets()}
                </div>
                <div className="why-use-section">
                    <img src={images.phone_preview} alt="Mobile preview" id="phone-preview" />
                </div>
            </div>
        )
    }

    renderWhyUseBullets() {
        const { darkMode } = this.state;
        const reasons = [
            "Read faster",
            "Made for students and learners",
            "Expand your capacity to focus",
            "Learn to absorb more words at once",
            "Understand passages faster and more accurately",
            "Reduce frustration and increase your reading efficiency",
            "Curb unintentional mind-wandering and eliminate skip-back",
            "Dynamically adjust your reading speed to match the material",
            "Increase your retention to remember more of what you read"
        ];

        return reasons.map(reason => {
            return (
                <div className="why-use-row">
                    <div className="why-use-check-container">
                        <i className="why-use-check fas fa-check-circle"></i>
                    </div>
                    <div className={`why-use-reason${darkMode ? " darkmode-font" : ""}`}>{reason}</div>
                </div>
            )
        })
    }

    renderTutorial() {
        const { showTutorial, darkMode } = this.state;
        if (showTutorial) {
            return <Tutorial cancel={this.closeTutorial} darkMode={darkMode} togglePlayPause={this.toggleShowPause} />
        }
    }


    render() {
        const { rawText, darkMode, placeholder, currentWordLocation, wordMap, showUnderline, autoFocus } = this.state;
        return (
            <div id="snotes-main" className={darkMode ? "darkmode-bg" : ""}>
                <div id="snotes-top-section" className={`set-z ${darkMode ? "darkmode-bg" : ""}`}>
                    <SNotesNavbarDesktop showTutorial={this.showTutorial} darkMode={darkMode} toggleDarkMode={this.toggleDarkMode} />
                    <SNotesNavbarMobile showTutorial={this.showTutorial} darkMode={darkMode} toggleDarkMode={this.toggleDarkMode} />
                    {this.renderCurrentWord()}
                    <div className="link" id="play-tutorial" onClick={this.showTutorial}>Play Tutorial</div>
                    {this.renderButtons()}
                    <TextArea
                        placeholder={placeholder}
                        rawText={rawText}
                        setRawText={this.setRawText}
                        darkMode={darkMode}
                        currentWordLocation={currentWordLocation}
                        showUnderline={showUnderline}
                        wordMap={wordMap}
                        clearLoop={this.clearLoop}
                        autoFocus={autoFocus}
                    />
                    <div id="snotes-top-section-background" className={`${darkMode ? "darkmode-bg" : ""}${showUnderline ? " space-bottom" : ""}`} />
                </div>
                {this.renderTutorial()}
                {this.renderHowItWorks()}
                {this.renderWhyUse()}
                <Footer />
            </div>
        )
    }
}

export default Main;