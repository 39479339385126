import React from 'react';

const SkipBackArrow = ({
    style,
    darkMode
}) => {
    return (
        <div style={style} className="tutorial-arrow">
            <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M46.6139 68.303C51.0831 56.274 59.9033 44.3344 69.5998 36.0422C75.1051 31.3939 81.4718 27.7948 87.9714 24.6525C91.3351 23.048 94.7653 21.6719 98.2287 20.41C99.2938 20.0362 103.747 19.4142 103.977 18.2934C104.68 14.5552 89.3464 20.0361 87.7048 20.7646C80.911 23.7451 74.103 27.1017 68.2229 31.7358C58.3602 39.4569 48.8801 49.9785 43.6421 61.489C40.5761 68.1497 37.3962 74.8438 35.8084 82.1252C35.1662 85.2257 34.7114 88.3334 34.705 91.4956C34.6604 93.6776 34.649 95.9737 34.8654 98.2032C35.0295 99.8283 36.1165 101.865 35.946 103.402C33.4381 102.215 31.919 98.694 29.4585 97.2455C26.4832 95.514 26.5977 98.4549 27.7653 100.344C29.5999 103.463 31.9705 106.301 34.2273 109.173C36.1234 111.654 37.9035 115.223 41.3811 113.585C44.3629 112.154 46.8651 109.5 49.4933 107.492C51.2699 106.166 55.739 104.114 55.6507 101.475C55.4329 93.2969 43.6239 106.855 42.4046 107.336C42.0389 106.08 42.283 104.583 42.1451 103.26C41.9548 101.333 41.423 99.5056 41.2328 97.5783C40.8049 93.9855 40.8326 90.2593 41.1544 86.6949C41.6488 80.5392 43.6306 73.7621 46.6139 68.303C46.1704 69.1145 48.2427 63.9221 46.6139 68.303Z" fill={darkMode ? "#E0E0E0" : "#465967"} />
            </svg>
        </div>
    )
}

export default SkipBackArrow;