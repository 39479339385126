import React from 'react';
import './App.css';
import Main from './components/Main';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

function App() {
	return (
		<div className="App">
			<Main />
		</div>
	);
}

export default App;
