import React from 'react';

const PlayPauseArrow = ({
    style,
    darkMode
}) => {
    return (
        <div style={style} className="tutorial-arrow">
            <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M35.6936 120.412C34.6357 120.011 32.1738 119.64 31.9197 118.324C31.5741 116.504 33.4663 116.659 34.6769 116.649C37.0675 116.711 40.5261 118.398 42.7236 117.73C30.9046 103.302 24.5999 85.5225 25.0197 66.634C25.409 47.8276 31.8109 27.0788 46.8891 14.9685C54.2858 9.04693 63.1471 5.18208 72.7401 6.09863C73.5336 6.21186 77.6537 6.3667 76.9108 7.86781C76.5241 8.65949 71.2545 8.35024 70.3695 8.48378C65.4558 8.96632 60.9489 10.3538 56.5742 12.6358C41.5678 20.551 32.9488 37.2688 30.098 53.4528C28.2754 64.1153 28.7112 75.4465 31.2937 85.9965C32.6052 91.4669 34.4966 96.8757 37.09 101.894C39.2461 106.089 42.7039 113.03 46.844 115.633C47.7398 111.468 44.6581 106.265 44.8724 101.936C44.9846 99.6329 46.8974 97.731 48.6571 99.9934C50.1014 101.855 49.8668 105.988 50.1411 108.25C50.4866 110.821 50.6999 113.248 50.8521 115.839C50.8926 117.731 51.8483 121.659 50.5561 123.139C48.0938 125.771 38.4097 121.348 35.6936 120.412C36.8329 120.844 34.5543 119.98 35.6936 120.412Z" fill={darkMode ? "#E0E0E0" : "#465967"}/>
            </svg>     
        </div>
    )
}

export default PlayPauseArrow;