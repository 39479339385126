import React, {Component} from 'react';
import TutorialStep from './TutorialStep';
import ModalButtons from './ModalButtons';
import '../Styles/tutorial.css';

class Tutorial extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 0
        };

        this.next = this.next.bind(this);
        this.setStepProps = this.setStepProps.bind(this);
    }
    
    setStepProps(step) {
        let copy, buttonId;
        switch(step) {
            case 1:
                copy = "Go back to beginning of text";
                buttonId = "restart";
                break;
            case 2:
                copy = "Go back one sentence";
                buttonId = "skip-back";
                break;
            case 3:
                copy = "Start SpeedNotes";
                buttonId = "play-pause";
                break;
            case 4:
                copy = "Pause SpeedNotes";
                buttonId = "play-pause";
                this.props.togglePlayPause();
                break;
            case 5:
                copy = "Go forward one sentence";
                buttonId = "skip-fwd";
                this.props.togglePlayPause();
                break;
            case 6:
                copy = "Decrease / Increase font size";
                buttonId = "font-buttons";
                break;
            case 7:
                copy = "Decrease / Increase Words Per Minute";
                buttonId = "wpm-select";
                break;
            // case 8:
            //     copy =  "Toggle Text to Speech";
            //     buttonId = "tts-btn";
            //     break;
            default:
                this.props.cancel();
                break;
        }
        this.setState({copy, buttonId, step});
    }

    next() {
        let {step} = this.state;
        if(step !== 7) {
            step++
            this.setStepProps(step)
        } else {
            this.props.cancel();
        }
    }

    renderInitStep() {
        const {darkMode} = this.props;
        return (
            <div className="snotes-modal-body">
                <div className="snotes-modal-header-container">
                    <div className={`snotes-modal-header${darkMode ? " darkmode-font" : ""}`}>
                        How does SpeedNotes work?
                    </div>
                    <div className="btn-close-x" onClick={this.props.cancel}>
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.150754 1.28138L1.28141 0.150722C1.38191 0.050219 1.48241 0.050219 1.58291 0.150722L14.8492 13.4171C14.9497 13.5176 14.9497 13.6181 14.8492 13.7186L13.7186 14.8492C13.6181 14.9497 13.5176 14.9497 13.4171 14.8492L0.150754 1.58288C0.0502512 1.48238 0.0502512 1.38188 0.150754 1.28138Z" fill={darkMode ? "#E0E0E0" : "#465967"} />
                            <path d="M0.150754 12.8141L12.8141 0.150769C12.9146 0.050267 13.0151 0.0502671 13.1156 0.15077L14.2462 1.28142C14.3467 1.38193 14.3467 1.48243 14.2462 1.58293L1.58291 14.2463C1.48241 14.3468 1.38191 14.3468 1.28141 14.2463L0.150754 13.1156C0.0502513 13.0151 0.0502512 12.9146 0.150754 12.8141Z" fill={darkMode ? "#E0E0E0" : "#465967"} />
                        </svg>
                    </div>
                </div>
                <div className="snotes-modal-main">
                    <p className={`snotes-modal-copy${darkMode ? " darkmode-font" : ""}`}>
                        SpeedNotes is a free web app that helps students and learners alike read faster. 
                        <br/><br/>
                        <b>By removing the need to move your eyes while reading, you can read your notes (and learn) much faster.</b>
                    </p>
                    <div className="modal-btn-container">
                        {this.renderButtons()}
                    </div>
                </div>
            </div>
        )
    }

    renderButtons() {
        const {darkMode} = this.props;
        return (
            <ModalButtons cancel={this.props.cancel} next={this.next} darkMode={darkMode} />
        )
    }
    
    renderTutorialStep() {
        const {darkMode} = this.props;
        const {copy, buttonId} = this.state;
        return <TutorialStep copy={copy} buttonId={buttonId} next={this.next} cancel={this.props.cancel} darkMode={darkMode} />
    }

    renderModalBody() {
        const {step, copy, buttonId} = this.state;
        if(step && copy && buttonId) {
            return this.renderTutorialStep();
        } else {
            return this.renderInitStep();
        }
    }

    render() {
        const {darkMode} = this.props;
        return (
            <div className={`modal-container${darkMode ? " darkmode" : ""}`}>
                {this.renderModalBody()}
            </div>
        )
    }
}

export default Tutorial;