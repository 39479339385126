import React from 'react';
import '../Styles/textDisplay.css';

const TextDisplay = ({word, fontSize, darkMode}) => {
    return (
        <div id="text-display-container">
            <div className="text-display-tick"></div>
            <div id="current-word-container">
                <h2 id="current-word" style={{fontSize}} className={darkMode ? ' darkmode' : ''}>{word}</h2>
            </div>
            <div className="text-display-tick"></div>
        </div>
    )
}

export default TextDisplay;