import React from 'react';

const WpmSelectArrow = ({
    style,
    darkMode
}) => {
    return (
        <div style={style} className="tutorial-arrow">
            <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M83.3861 68.303C78.9169 56.274 70.0967 44.3344 60.4002 36.0422C54.8949 31.3939 48.5282 27.7948 42.0286 24.6525C38.6649 23.048 35.2347 21.6719 31.7713 20.41C30.7062 20.0362 26.2533 19.4142 26.0235 18.2934C25.3197 14.5552 40.6536 20.0361 42.2952 20.7646C49.089 23.7451 55.897 27.1017 61.7771 31.7358C71.6398 39.4569 81.1199 49.9785 86.3579 61.489C89.4239 68.1497 92.6038 74.8438 94.1916 82.1252C94.8338 85.2257 95.2886 88.3334 95.295 91.4956C95.3396 93.6776 95.351 95.9737 95.1346 98.2032C94.9705 99.8283 93.8835 101.865 94.054 103.402C96.5619 102.215 98.081 98.694 100.541 97.2455C103.517 95.514 103.402 98.4549 102.235 100.344C100.4 103.463 98.0295 106.301 95.7727 109.173C93.8766 111.654 92.0965 115.223 88.6189 113.585C85.6371 112.154 83.1349 109.5 80.5067 107.492C78.7301 106.166 74.261 104.114 74.3493 101.475C74.5671 93.2969 86.3761 106.855 87.5954 107.336C87.9611 106.08 87.717 104.583 87.8549 103.26C88.0452 101.333 88.577 99.5056 88.7672 97.5783C89.1951 93.9855 89.1674 90.2593 88.8456 86.6949C88.3512 80.5392 86.3694 73.7621 83.3861 68.303C83.8296 69.1145 81.7573 63.9221 83.3861 68.303Z" fill={darkMode ? "#E0E0E0" : "#465967"} />
            </svg>
        </div>
    )
}

export default WpmSelectArrow;