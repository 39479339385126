import React, {Component} from 'react';
import ModalButtons from './ModalButtons';
import FontSizeArrow from './Arrows/FontSizeArrow';
import RestartArrow from './Arrows/RestartArrow';
import PlayPauseArrow from './Arrows/PlayPauseArrow';
// import TtsArrow from './Arrows/TtsArrow';
import SkipBackArrow from './Arrows/SkipBackArrow';
import SkipFwdArrow from './Arrows/SkipFwdArrow';
import WpmSelectArrow from './Arrows/WpmSelectArrow';

class TutorialStep extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.handleNewButtonId = this.handleNewButtonId.bind(this);
        this.setArrow = this.setArrow.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentDidMount() {
        const {buttonId} = this.props;
        if(buttonId) {
            this.handleNewButtonId(buttonId);
        }

        this.handleResize();
    }

    componentDidUpdate() {
        const {buttonId} = this.props;
        if(this.state.buttonId !== buttonId) {
            this.handleNewButtonId(buttonId);
        }
    }

    componentWillUnmount() {
        this.removeZTop(this.state.buttonId);
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        const {isMobile} = this.state;
        const {innerWidth} = window;
        if (innerWidth > 767 && isMobile) {
            this.setState({isMobile: false})
        } else if(innerWidth < 768 && !isMobile) {
            this.setState({isMobile: true})
        }
    }

    removeZTop(buttonId) {
        const element = document.getElementById(buttonId);
        element.classList.remove('z-top');
    }
    
    addZTop(buttonId) {
        const element = document.getElementById(buttonId);
        element.classList.add('z-top');
    }

    async handleNewButtonId(buttonId) {
        if(this.state.buttonId) {
            this.removeZTop(this.state.buttonId);
        }
        this.addZTop(buttonId);
        const {arrowTop, arrowLeft}= await this.setArrow(buttonId);
        this.setState({buttonId, arrowTop, arrowLeft})
    }

    async setArrow(buttonId) {
        const btn = document.getElementById(buttonId);
        const container = document.getElementById('tutorial-step-container');
        const btnRect = btn.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        const arrowTop = btnRect.top - containerRect.top - 180 // arrow height + some extra space;
        const arrowLeft = btnRect.left - containerRect.left - 20 // just to line it up;
        return {arrowTop, arrowLeft};
    }

    renderCopy() {
        const {copy, darkMode} = this.props;
        return (
            <div className={`modal-step-copy${darkMode ? " darkmode-font" : ""}`}>{copy}</div>
        )
    }

    renderButtons() {
        const {next, cancel, darkMode} = this.props;
        return (
            <div className="modal-btn-row">
                <ModalButtons cancel={cancel} next={next} darkMode={darkMode} />
            </div>
        )
    }

    renderArrow() {
        const {darkMode} = this.props;
        const {arrowTop, arrowLeft, isMobile, buttonId} = this.state;
        if(!isMobile && buttonId) {
            switch(buttonId) {
                case 'restart':
                    return <RestartArrow style={{top: arrowTop, left: arrowLeft}} darkMode={darkMode} />
                case 'font-buttons':
                    return <FontSizeArrow style={{top: arrowTop, left: arrowLeft}} darkMode={darkMode} />
                case 'play-pause':
                    return <PlayPauseArrow style={{top: arrowTop, left: arrowLeft}} darkMode={darkMode} />
                // case 'tts-btn':
                //     return <TtsArrow style={{top: arrowTop, left: arrowLeft}} darkMode={darkMode} />
                case 'skip-fwd':
                    return <SkipFwdArrow style={{top: arrowTop, left: arrowLeft}} darkMode={darkMode} />
                case 'skip-back':
                    return <SkipBackArrow style={{top: arrowTop, left: arrowLeft}} darkMode={darkMode} />
                case 'wpm-select':
                    return <WpmSelectArrow style={{top: arrowTop, left: arrowLeft}} darkMode={darkMode} />
                default:
                    console.error('unknown buttonId')
                    return;
            }
        }
    }

    render() {
        return (
            <div id="tutorial-step-container">
                {this.renderCopy()}
                {this.renderButtons()}
                {this.renderArrow()}
            </div>
        )
    }
} 

export default TutorialStep;