import React from 'react';

const TextArea = ({
        rawText,
        placeholder,
        setRawText,
        darkMode,
        showUnderline,
        wordMap,
        clearLoop,
        autoFocus
    }) => {
        if(!showUnderline) {
            return (
                <textarea
                    id="main-textarea"
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                    onChange={e => setRawText(e.target.value)}
                    value={rawText}
                    className={darkMode ? "darkmode" : ""}
                />
            )
        } else {
            return (
                <div id="main-textarea" className={`text-left${darkMode ? " darkmode" : ""}`} onClick={() => clearLoop(true)}>
                    {wordMap}
                </div>
            )
        }
}

export default TextArea;