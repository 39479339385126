const images = {
    phone_preview: require('./iphone_preview.png'),
    logo: require('./logo.svg'),
    // buttons
    pause: require('./pause.svg'),
    resume: require('./resume.svg'),
    // how it works (hiw)
    hiw_1: require('./hiw_1.svg'),
    hiw_2: require('./hiw_2.svg'),
    hiw_3: require('./hiw_3.svg')
}

export default images;