import React from 'react';

const PlayPauseBtn = ({
    showPause,
    onclick
}) => {
    const button = () => {
        if(showPause) {
            return (
                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="25" cy="25" r="25" fill="#2D9CDB"/>
                    <rect x="18" y="15" width="4" height="21" rx="2" fill="#F2F2F2"/>
                    <rect x="28" y="15" width="4" height="21" rx="2" fill="#F2F2F2"/>
                </svg>
            )
        } else {
            return (
                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="25" cy="25" r="25" fill="#2D9CDB"/>
                    <path d="M35.144 24.0323C35.7888 24.4119 36.1112 24.9677 36.1112 25.6998C36.1112 26.4318 35.7888 26.9876 35.144 27.3672L20.9574 35.8265C20.3395 36.2061 19.6946 36.2061 19.0229 35.8265C18.3781 35.474 18.0557 34.9182 18.0557 34.159V17.2405C18.0557 16.4271 18.3915 15.8577 19.0632 15.5324C19.7618 15.1799 20.3932 15.1935 20.9574 15.5731L35.144 24.0323Z" fill="#F2F2F2"/>
                </svg>
            )
        }
    }
    
    return (
        <div className="pointer" id="play-pause" onClick={onclick}>
            {button()}
        </div>
    )
}

export default PlayPauseBtn;