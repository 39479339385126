import React from 'react';

const SkipFwdBtn = ({onclick, darkMode}) => {
    return (
        <div id="skip-fwd" className={`pointer${darkMode ? " darkmode" : ""}`} onClick={onclick}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M2.69549 2.05883C2.6071 1.99993 2.50745 1.95042 2.22828 2.08993L2.22107 2.09354L2.2138 2.09702C2.10615 2.14867 2.0708 2.19507 2.05449 2.22245C2.03531 2.25466 2 2.33421 2 2.50725V15.5007C2 15.6465 2.03007 15.718 2.0504 15.7527C2.06995 15.7861 2.11233 15.8403 2.2261 15.9019L2.23846 15.9086C2.46936 16.0378 2.5828 16.0167 2.73025 15.927L2.74142 15.9202L13.7463 9.42066C13.9501 9.30182 14 9.1977 14 9.00396C14 8.81022 13.9502 8.70609 13.7463 8.58725L13.7415 8.58441L2.71809 2.07388L2.69549 2.05883ZM3.7791 0.377755C3.00131 -0.12751 2.13487 -0.0968485 1.34127 0.297383C0.93126 0.4956 0.578778 0.791667 0.336133 1.1991C0.0948985 1.60417 0 2.0556 0 2.50725V15.5007C0 15.9379 0.0949345 16.3713 0.324597 16.7635C0.553789 17.1548 0.884041 17.4483 1.26759 17.6572C2.07463 18.1066 2.95628 18.1276 3.76345 17.6394L14.7537 11.1485C14.7546 11.1479 14.7554 11.1474 14.7563 11.1469C15.5508 10.6826 16 9.93363 16 9.00396C16 8.07429 15.5508 7.32531 14.7563 6.861L3.7791 0.377755Z" fill={darkMode ? "#BDBDBD" : "#465967"} />
                <rect x="16" width="2" height="18" fill={darkMode ? "#BDBDBD" : "#465967"}/>
            </svg>
        </div>
    )
}

export default SkipFwdBtn;