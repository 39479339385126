import React from 'react';

const SkipFwdArrow = ({
    style,
    darkMode
}) => {
    return (
        <div style={style} className="tutorial-arrow">
            <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M41.837 85.776C40.5472 75.313 42.49 63.2646 46.4642 53.5423C48.7433 48.0659 52.0546 43.0274 55.6375 38.2713C57.5 35.8259 59.4982 33.5216 61.5642 31.288C62.2051 30.6115 65.2419 28.4689 64.9846 27.5648C64.0801 24.5731 54.8924 34.385 53.9628 35.5387C50.1021 40.2884 46.374 45.3175 43.814 50.9258C39.5004 60.295 36.5333 71.5601 37.0607 81.9369C37.3402 87.9548 37.5486 94.0402 39.1508 99.9497C39.858 102.453 40.7056 104.891 41.9037 107.198C42.7008 108.805 43.5658 110.483 44.5729 112.026C45.3116 113.148 46.8852 114.221 47.3446 115.406C45.0496 115.492 42.5936 113.502 40.2339 113.379C37.388 113.246 38.5908 115.346 40.1676 116.28C42.7026 117.857 45.5248 119.027 48.2759 120.264C50.6136 121.353 53.2795 123.279 55.213 120.766C56.8608 118.592 57.691 115.708 58.859 113.247C59.6608 111.607 62.1656 108.416 61.097 106.526C57.8264 100.648 54.3022 115.01 53.5887 115.823C52.8421 115.046 52.4523 113.863 51.8477 112.951C50.9748 111.618 49.8889 110.488 49.016 109.155C47.3349 106.699 45.938 103.973 44.8189 101.252C42.841 96.5781 41.7203 90.8867 41.837 85.776C41.8196 86.5357 41.3681 81.965 41.837 85.776Z" fill={darkMode ? "#E0E0E0" : "#465967"} />
            </svg>
        </div>
    )
}

export default SkipFwdArrow;