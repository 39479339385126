import React from 'react';

const RestartBtn = ({onclick, darkMode}) => {
    return (
        <div id="restart" className={`pointer${darkMode ? " darkmode" : ""}`} onClick={onclick}>
            <svg width="32" height="18" viewBox="0 0 32 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M29.3045 2.05883C29.3929 1.99993 29.4926 1.95042 29.7717 2.08993L29.7789 2.09354L29.7862 2.09702C29.8939 2.14867 29.9292 2.19507 29.9455 2.22245C29.9647 2.25466 30 2.33421 30 2.50725V15.5007C30 15.6465 29.9699 15.718 29.9496 15.7527C29.93 15.7861 29.8877 15.8403 29.7739 15.9019L29.7615 15.9086C29.5306 16.0378 29.4172 16.0167 29.2698 15.927L29.2586 15.9202L18.2537 9.42066C18.0499 9.30182 18 9.1977 18 9.00396C18 8.81022 18.0498 8.70609 18.2537 8.58725L18.2585 8.58441L29.2819 2.07388L29.3045 2.05883ZM28.2209 0.377755C28.9987 -0.12751 29.8651 -0.0968485 30.6587 0.297383C31.0687 0.4956 31.4212 0.791667 31.6639 1.1991C31.9051 1.60417 32 2.0556 32 2.50725V15.5007C32 15.9379 31.9051 16.3713 31.6754 16.7635C31.4462 17.1548 31.116 17.4483 30.7324 17.6572C29.9254 18.1066 29.0437 18.1276 28.2365 17.6394L17.2463 11.1485C17.2454 11.1479 17.2446 11.1474 17.2437 11.1469C16.4492 10.6826 16 9.93363 16 9.00396C16 8.07429 16.4492 7.32531 17.2437 6.861L28.2209 0.377755Z" fill={darkMode ? "#BDBDBD" : "#465967"}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M13.3045 2.05883C13.3929 1.99993 13.4926 1.95042 13.7717 2.08993L13.7789 2.09354L13.7862 2.09702C13.8939 2.14867 13.9292 2.19507 13.9455 2.22245C13.9647 2.25466 14 2.33421 14 2.50725V15.5007C14 15.6465 13.9699 15.718 13.9496 15.7527C13.93 15.7861 13.8877 15.8403 13.7739 15.9019L13.7615 15.9086C13.5306 16.0378 13.4172 16.0167 13.2698 15.927L13.2586 15.9202L2.25369 9.42066C2.04985 9.30182 2 9.1977 2 9.00396C2 8.81022 2.04985 8.70609 2.25368 8.58725L2.25855 8.58441L13.2819 2.07388L13.3045 2.05883ZM12.2209 0.377755C12.9987 -0.12751 13.8651 -0.0968485 14.6587 0.297383C15.0687 0.4956 15.4212 0.791667 15.6639 1.1991C15.9051 1.60417 16 2.0556 16 2.50725V15.5007C16 15.9379 15.9051 16.3713 15.6754 16.7635C15.4462 17.1548 15.116 17.4483 14.7324 17.6572C13.9254 18.1066 13.0437 18.1276 12.2365 17.6394L1.24632 11.1485C1.24544 11.1479 1.24457 11.1474 1.2437 11.1469C0.449163 10.6826 0 9.93363 0 9.00396C0 8.07429 0.449163 7.32531 1.2437 6.861L12.2209 0.377755Z" fill={darkMode ? "#BDBDBD" : "#465967"}/>
            </svg>
        </div>
    )
}

export default RestartBtn;