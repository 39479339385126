import React from 'react';
import images from '../images';
import SocialLink from './SocialLink';

const Footer = () => {
    return (
        <div className="footer-container">
            <img src={images.logo} alt="SpeedNotes" id="footer-logo" />
            <div id="footer-social-container">
                <SocialLink name="facebook" />
                <SocialLink name="instagram" />
                <SocialLink name="twitter" />
            </div>
            <div>© {new Date().getFullYear()} SpeedNotes. Made with <span role="img" aria-label="love" className="heart-emoji">♥</span></div>
            <a title="Privacy Policy" href="/blog/privacy-policy" className="footer-link">Privacy Policy</a>
        </div>
    )
}

export default Footer;