import React, {Fragment} from 'react';

const ModalButtons = ({
    cancel,
    next,
    darkMode
}) => {
    return (
        <Fragment>
            <div className={`modal-btn modal-cancel-btn${darkMode ? " darkmode-font" : ""}`} onClick={cancel}>Cancel</div>
            <div className="modal-btn modal-next-btn" onClick={next}>Next</div>
        </Fragment>
    )
}

export default ModalButtons;