import React from 'react';

const TutorialIcon = ({
    showTutorial,
    darkMode
}) => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" id="tutorial-icon" onClick={showTutorial}>
            <path d="M12.57 8.116C13.494 8.116 14.2407 8.37733 14.81 8.9C15.3793 9.42267 15.664 10.1227 15.664 11C15.664 11.98 15.3607 12.708 14.754 13.184C14.1473 13.6507 13.326 13.884 12.29 13.884L12.248 15.214H11.142L11.086 13.002H11.492C12.416 13.002 13.1393 12.862 13.662 12.582C14.1847 12.302 14.446 11.7747 14.446 11C14.446 10.44 14.278 9.99667 13.942 9.67C13.606 9.34333 13.1533 9.18 12.584 9.18C12.0053 9.18 11.548 9.33867 11.212 9.656C10.8853 9.964 10.722 10.3887 10.722 10.93H9.518C9.518 10.37 9.644 9.88 9.896 9.46C10.148 9.03067 10.5027 8.69933 10.96 8.466C11.4267 8.23267 11.9633 8.116 12.57 8.116ZM11.688 18.084C11.4453 18.084 11.24 18 11.072 17.832C10.904 17.664 10.82 17.4587 10.82 17.216C10.82 16.9733 10.904 16.768 11.072 16.6C11.24 16.432 11.4453 16.348 11.688 16.348C11.9213 16.348 12.1173 16.432 12.276 16.6C12.444 16.768 12.528 16.9733 12.528 17.216C12.528 17.4587 12.444 17.664 12.276 17.832C12.1173 18 11.9213 18.084 11.688 18.084Z" fill={darkMode ? "#E0E0E0" : "#828282"} />
            <rect x="0.5" y="0.5" width="24" height="24" rx="12" stroke={darkMode ? "#E0E0E0" : "#828282"}/>
        </svg>

    )
}

export default TutorialIcon;