import React from 'react';

const RestartArrow = ({
    style,
    darkMode
}) => {
    return (
        <div style={style} className="tutorial-arrow">
            <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.4058 117.494C23.389 116.684 20.8252 115.408 21.0094 113.893C21.2706 111.8 23.287 112.639 24.6153 113.058C27.2097 113.974 30.402 117.029 33.0414 117.086C25.1762 97.2676 24.5229 75.7794 31.6182 55.4778C38.6512 35.2544 52.9463 15.0628 73.7015 7.30504C83.8762 3.52027 94.9312 2.48234 105.107 6.88109C105.936 7.28544 110.39 8.91608 109.05 10.2776C108.349 10.9974 102.69 8.7914 101.675 8.6217C96.1283 7.39936 90.7088 7.30122 85.1197 8.21855C65.917 11.4597 50.6117 26.4996 41.8063 43.0097C36.0659 53.9069 32.562 66.3299 31.6816 78.6695C31.195 85.058 31.3646 91.5858 32.4397 97.9398C33.3252 103.248 34.6707 111.991 38.2872 116.278C40.7305 112.088 39.1861 105.36 40.9414 100.749C41.8734 98.2952 44.6348 96.9152 45.7656 99.9895C46.6922 102.518 44.9834 106.91 44.4889 109.456C43.9639 112.362 43.3447 115.065 42.601 117.925C41.9806 119.988 41.6464 124.58 39.7122 125.724C36.0931 127.699 27.0493 119.472 24.4058 117.494C23.3108 116.621 25.5009 118.366 24.4058 117.494Z" fill={darkMode ? "#E0E0E0" : "#465967"}/>
            </svg>        
        </div>
    )
}

export default RestartArrow;